<template>
  <div class="alert-container">
    <v-alert
      v-for="(alert, index) in alerts"
      :key="`dashboard-alert-${index}`"
      :value="alert.value"
      dense
      prominent
      border="left"
      :dismissible="dismissible"
      :type="alert.level"
      :color="`${alert.level}`"
    >
      <div class="d-flex justify-space-between flex-row strec">
        <div class="d-flex flex-column justify-center">
          <span class="text-h7 font-weight-medium">{{ alert.title }}</span>
          <template v-if="alert.text">
            <span class="text-subtitle-1">{{ alert.text }}</span>
          </template>
        </div>
        <div
          v-if="alert.link || (alert.api_call && alert.api_call_method)"
          class="flex-center"
        >
          <v-btn
            color="primary"
            small
            dense
            :disabled="disableLinks"
            @click="click(alert)"
          >
            {{ alert.link_text }}
          </v-btn>
        </div>
      </div>
    </v-alert>
  </div>
</template>

<script>
import Vue from 'vue';
// import SnackbarMixin from '@components/mixins/SnackbarMixin.vue';

export default {
  name: 'AlertsList',
  // mixins: [
  //   SnackbarMixin,
  // ],
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    alerts: {
      type: [Object, Array],
      required: true,
    },
    disableLinks: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goToUrl(link) {
      window.location.href = link;
    },
    click(alert) {
      if (alert.api_call && alert.api_call_method) {
        if (alert.api_call_method === 'POST') {
          Vue.axios.post(
            alert.api_call,
          )
            .then(() => {
              // this.showSnackbar(
              //   'success',
              //   this.$t('success'),
              // );
              this.$emit('refresh');
            }).catch(() => {
              // this.showSnackbar(
              //   'error',
              //   this.$t('applicationError.header'),
              // );
            });
        }

        if (alert.api_call_method === 'GET') {
          Vue.axios.get(
            alert.api_call,
          )
            .then(() => {
              // this.showSnackbar(
              //   'success',
              //   this.$t('success'),
              // );
              this.$emit('refresh');
            }).catch(() => {
              // this.showSnackbar(
              //   'error',
              //   this.$t('applicationError.header'),
              // );
            });
        }
        return;
      }

      const target = alert.link;
      if (target.startsWith('https://')) {
        this.goToUrl(target);
      }

      if (target.startsWith('#/')) {
        this.$router.push({ name: target.substring(2) });
      }
    },
  },
};
</script>

<style lang="css" scoped>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
