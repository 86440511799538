<template>
  <v-app>
    <div grid-list-sm>
      <div>
        <v-row
          dense
          wrap
        >
          <v-col class="col-12 col-sm-12">
            <transition name="fade">
              <v-alert
                v-if="invoiceAppearMessage"
                type="info"
                dense
                prominent
                border="left"
                :value="true"
              >
                {{ $t('invoices.you_will_recive_invoice', { app_name: 'appName' }) }}
              </v-alert>
            </transition>
          </v-col>
        </v-row>
      </div>
      <v-row dense>
        <v-col class="col-12">
          <page-table />
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import PageTable from '@components/invoices/InvoicesTable.vue';
import common from '@components/financial/common.js';

export default {
  components: {
    'page-table': PageTable,
  },
  data() {
    return {
      sumDataLoaded: false,
      paymentsDayByDay: [],
      invoiceAppearMessage: true,
      paymentsSum: 0,
      icons: common.icons,
    };
  },
  mounted() {
    setTimeout(() => {
      this.invoiceAppearMessage = false;
    }, 7000);
  },
};
</script>
